.Page {
    display: grid;
    grid-template-columns: minmax(100px, 10%) 1fr;
    overflow: hidden;
    width: 100vw;
    min-height: calc(100vh - $footerHeight - $topNavHeight);
}

.Page-Header {
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    align-items: center;
    justify-content: space-between;
    flex: 1 1 90vw; /*  Stretching: */
    // flex: 0 1 90vw; /*  No stretching: */
    // margin: 5px;
}

.Page .slideContainer {
    min-height: calc(100vh - $footerHeight - $topNavHeight);
}

.Project-Information {
    display: grid;
    grid-template-columns: minmax(150px, 500px) 1fr;
}

.Project-Attributes {
    padding: $appPadding;
    background: #1c2022;
    border-left: 1px solid #3a4047;
    min-height: 80vh;
    height: 100%;
}

.SlideShow {
    width: 50vw;
}

.Project-Attr,
.Main-Image {
    padding: $appPadding $appPadding 0 $appPadding;
}

.Project-Attr:first-child {
    margin-top: $topNavHeight;
    // border-top: 1px solid #3a4047;
}

.Project-Attr h3 {
    border-top: 1px solid #3a4047;
    padding: $appPadding;
    border-radius: 0;
    padding-bottom: .5rem;
}

.Project-Description {
    display: grid;
    grid-template-columns: 1fr minmax(200px, 30%);
}

.Attr {
    margin-left: $appMargin;
    color: $appColor;
}

// Page Navigation

#nav {
	position: relative;
	top: 0;
	left: 0;
	transform: translate(-30%, -30%);
	height: 200px;
	width: 200px;
	border-radius: 100%;
	border: 3px dotted $appColorFocus;
	transition: transform 0.3s;
	transition-delay:0.5s;
	
	// &:hover {
	// 	transform: translate(10%,12%);
	// 	transition-delay:0s;
	// }

	#nav-bg {
		position: absolute;
		height: 90%;
		width: 90%;
		top: 5%;
		left: 5%;
		border: 3px dotted $appColorFocus;
		border-radius: 100%;
		opacity: 0.6;
		
		// .inner {
		// 	position: absolute;
		// 	height: 60%;
		// 	width: 60%;
		// 	top: 5%;
		// 	left: 5%;
		// 	padding: 5%;
		// 	border-radius: 100%;
		// 	border: 3px dotted $appColorFocus;
		// 	opacity: 0.4;
		// }
	}
	
	#nav-inside {
		position:relative;
		height:100%;
		width:100%;
		
		.item {
			position:absolute;
			left:50%;
			height:100%;
			width:1px;
			transition: transform 1s;
			cursor:pointer;
			
			&.active .sub h3 {
				color: $appColorFocus;
			}

			.sub {
				position: absolute;
				height: 60px;
				width: 60px;
				top: -30px;
				left: -30px;
				background: #17191b;
				transition: transform 1s background 0.3s;
				
				h3 {
					position:absolute;
					left:50%;
					top:50%;
					transform:translate(-50%, -50%);
					text-align:center;

                    &:hover {
                        color:white;
                    }
				}
			}
		}
	}
}

#Main-Content {
	padding: $appPadding;
	margin-left: 5rem;

	.item {
		position: absolute;
		opacity: 0;
		transform: translate(-200%,0);
		transition: transform 1s, opacity 1s;
		
		&.active {
			opacity:1;
			transform:translate(0,0);
		}
		
		h3 {
			margin: $appMargin/2 0;
			font-size: 1rem;
		}
	}
}

 #Main-Content .Content {
	display: flex;
	flex-direction: row;
	min-height: 50vh;
}

.Written-Content {
	margin: 0 $appMargin*2;
	max-width: 30vw;
}

.Visual-Content {
	max-height: 50vh;
	max-width: 50vw;
	margin: $appMargin;
	margin-right: 0;

	img, video {
		max-height: 50vh;
	}

	h5 {
		max-width: 35vw;
	}
}
