$baseFont: Hind, sans-serif;
$baseFontSize: 14px;
$baseLineHeight: 1.4em;

$appMargin: 0.625rem;
$appPadding: 0.625rem;
$appBorderRadius: 0.375rem;
$appIndent: 1.5rem;

$appColor: #e9e9e9;
$appColorSecondary: #919295;
$appColorTeritiary: #76797f;
$appColorDark: #17191b;
$appColorFocus: #ea21ad;
$appColorInverted: #000000;

$appBorderColor: #4e5155;
$queryColWidth: 20rem;
$bodyColWidth: 35rem;
$topNavHeight: 4rem;
$footerHeight: 2.5rem;
