.MuiFormGroup-root {
margin-left: $appMargin*2.5;
}

.Component-Container {
  padding-bottom: $appPadding;  
}

.css-yafqtf-MuiPaper-root-MuiAccordion-root {
  background-color: $appColorDark;
}

.css-1xkvp8t-MuiSvgIcon-root {
  fill: $appColorSecondary;
}
