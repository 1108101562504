.Research-Item-Container {
    padding: $appPadding;
    text-align: center;
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(auto-fit, minmax(350px, 490px));
}

.Research-Item h3,
.Research-Item h4,
.Research-Item p {
    text-align: left;
}

.Research-Item {
    margin: $appMargin;
    padding: $appPadding;
    max-height: 80vh;
}

.Research-Item img {
    max-height: 45vh;
    padding: $appPadding;
}

.Research-Item a:hover h3 {
    color: $appColorFocus;
}

@media screen and (max-width: 480px) {

    .Research-Item-Container {
        padding: 0;
        
        img {
            padding: 0;
            padding-top: $appPadding;
        }
    }
}