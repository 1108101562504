:root {
  color-scheme: dark;
}

* {
  margin:0;
	padding:0;
}

body {
  overflow-x: hidden;
  margin: 0;
  min-height: 100vh;
  margin: 0;
  position: relative;
}

#root {
  min-height: 90vh;
}

body::after {
  content: '';
  display: block;
  height: $footerHeight;
  /* Set same as footer's height */
}

.Content {
  display: grid;
  grid-template-columns: minmax(175px, 17%) 1fr;
  width: 100vw;
  // margin: $appMargin;
  min-height: 80vh;
}

.Construction {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
  direction: column;

  img {
    width: 200px;
    height: 180px;
  }
}

@media screen and (max-width: 768px) {

  .Navigation {
    display: flex;
    flex-direction: row; 
  }

  .Navigation_branding {
    display: flex;
    flex-direction: column;
    align-items: left;
    // margin-top: 1rem;
  }

  .Navigation_links a {
    padding: 0;
  }

  .react-slideshow-container {
    margin: 6rem 1rem;
  }

}

@media screen and (max-width: 480px) {

  h1 {
    padding: 0;
    font-size: 2.7rem;
    line-height: 1;
    margin: 0;
    letter-spacing: .08em;
  }

  .Navigation {
    padding: $appPadding*2;
  }

  .Navigation a {
    flex: 0;
    display: inline;
    padding: 0;
  }

  .Navigation_branding {
    align-items: normal;
    padding-right: $appPadding*2;
    padding-left: 0;

    h3 {
      padding: 0;
      line-height: 1.4;
    }
  }

  h3 {
    font-size: 1rem;
    padding: .5rem $appPadding/2;
  }

  h4 {
    padding: $appPadding/2;
  }

  p {
    padding: $appPadding $appPadding/2;
  }

  img {
    max-width: 80%;
  }

  h2 {
    margin: $appPadding $appPadding $appPadding/2 $appPadding/2;
  }

  .Content {
    display: flex;
    flex-direction: column;

    .css-dmmspl-MuiFormGroup-root {
      display: inherit;
    }

    .css-zt11pp-MuiFormControlLabel-root {
      border: 1px solid #919295;
      border-radius: 5px;
      margin: 3px;
    }
    .css-zt11pp-MuiFormControlLabel-root .MuiFormControlLabel-label {
      border: none;
      margin: 0;
      padding: .4rem;
    }
    .css-15v22id-MuiAccordionDetails-root {
      padding: 0;
      margin-left: $appMargin;
    }

    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
      margin: 10px 0;
    }

    .css-1lnbrrr-MuiAccordionDetails-root {
      margin: 0 0 0 0;
    }

    // .MuiFormGroup-root {
    //   padding-bottom: 1rem;
    //   border-bottom: 1px solid #3a4047;
    // }
  }

  .Project-Attributes {
    padding: 0;
  }

  .Project-Attr {
    padding: $appPadding/2;
  }

  .Project-Information {
    display: flex;
    flex-direction: column;
  }

  .Page {
    display: flex;
    flex-direction: column;

    #nav {
      transform: translate(50%, 10%);
    }

    #Main-Content {
      margin-left: 0;
      margin-top: $appMargin*3;

      img, video {
        max-width: 90vw;
      }

      .Content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .Written-Content {
          max-width: 90vw;
          margin: 0;
          margin-bottom: calc($footerHeight + $appMargin);
        }

        .Visual-Content, 
        .Visual-Content h5 {
          max-width: 90vw;
        }
      }
    }
  }

  .Footer {
    position: fixed;
    bottom: 0;
    height: $footerHeight;
  }

  #visualization {
    transform: translate(-10%, 0%);
  }

  li {
    padding-left: $appPadding/2;
  }
}

#Main {
  visibility: hidden;
}
