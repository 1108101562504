/* Slideshow */

.react-slideshow-container {
    margin: auto $appMargin*8;
}

.Slide {
    text-align: center;
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';

    h3 {
        padding-bottom: $appPadding/2;
    }

    img {
        max-height: 57vh;
    }
}

.Page .Slide img {
    max-height: 40vh;
}

/* Buttons */

.default-nav {
    border: 1px solid $appColorFocus!important;
    background: transparent!important;
    width: 20px!important;
    height: 20px!important;
}

button.nav > svg {
    padding: 5px!important;
    fill: $appColorFocus!important;
}

