// Navigation component styles

// Top navigation
.Navigation {
  grid-column-start: 1;
  grid-column-end: span 2;
  display: flex;
  flex-direction: row;
  background: #1c2022;
  border-bottom: 1px solid #3a4047;
}

.Navigation_branding {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: $appPadding;

  h2 {
    color: $appColorFocus;
    font-size: 1.25rem;
  }

  h3 {
    padding: $appPadding $appPadding/2 0  $appPadding/2;
  }
}

// Links
.Navigation a {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  padding: $appPadding/2 1.25rem;
}

// Links
.Navigation_links {

  a.active h3 {
    color: $appColorFocus;
  }

  a:hover h3 {
    color: white;
  }

}
