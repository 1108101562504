.Sidebar {
    border-right: 1px solid #3a4047;
}

.Item-Container {
    // margin-top: $appMargin;
    padding: $appPadding;
    text-align: center;
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(auto-fit, minmax(350px, 405px));
}

.Portfolio-Item h2,
.Portfolio-Item h3 {
    text-align: center;
}

.Portfolio-Item {
    margin: $appMargin;
    padding: $appPadding;
    border: #3a4047 1px solid;
    background-color: #1c2022;
    border-radius: $appBorderRadius;
    max-height: 65vh;
    position: relative;
}

.Portfolio-Item .Item-Image {
    max-height: 30vh;
    padding: $appPadding;
}

.Portfolio-Item:hover {
    border: 1px solid $appColorFocus;
}

.Tag-Container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
}

.Tag,
.btn {
    font-size: .8rem;
    color: $appColorSecondary;
    padding: $appPadding/3 $appPadding/2;
    margin: $appPadding/3 $appPadding/2;
    border-radius: 5px;
    border: 1px solid $appColorSecondary;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 1px 0px;
}

.Tag:hover {
    cursor: default;
}

/* Style the buttons */
.btn {
    outline: none;
    cursor: pointer;
}

/* Add a light grey background on mouse-over */
.btn:hover {
    border: 1px solid $appColorFocus;
    color: white;
}

/* Add a dark background to the active button */
.btn.active {
    background-color: $appColorFocus;
    border: 1px solid $appColorFocus;
    color: white;
    font-weight: bold;
}

.IIB-Award {
    max-height: 100px;
    position: absolute; 
    top: 12%; 
    left: -5%; 
    z-index: 1;
    rotate: -20deg;
}

// .IIB-Award-2023 {
//     rotate: -20deg;
// }

// .IIB-Award-2022 {
//     rotate: 10deg;
// }

.IIB-Award:hover {
    animation: rotation 1s linear;
}

@keyframes rotation {
    0% { transform: rotate(-20deg); } 
    20% { transform: rotate(15deg); } 
    40% { transform: rotate(-10deg); } 
    60% { transform: rotate(5deg); } 
    80% { transform: rotate(-5deg); } 
    100% { transform: rotate(0deg); } 
}
