$fontPath: "../../../public/font/";

// Hind
@font-face {
    font-family: "Hind";
    src: url(#{$fontPath + 'Hind/Hind-Light.ttf'}) format("ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Hind";
    src: url(#{$fontPath + 'Hind/Hind-Regular.ttf'}) format("ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Hind";
    src: url(#{$fontPath + 'Hind/Hind-Medium.ttf'}) format("ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Hind";
    src: url(#{$fontPath + 'Hind/Hind-SemiBold.ttf'}) format("ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Hind";
    src: url(#{$fontPath + 'Hind/Hind-Bold.ttf'}) format("ttf");
    font-weight: 700;
    font-style: normal;
}

// Amasis
@font-face {
    font-family: "Amasis";
    src: url(#{$fontPath + 'Amasis/Amasis MT Regular.ttf'}) format("ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Amasis";
    src: url(#{$fontPath + 'Amasis/Amasis MT Std Black.otf'}) format("otf");
    font-weight: 800;
    font-style: normal;
}

// Gloridot
@font-face {
    font-family:"gloridot";
    src: url("https://use.typekit.net/af/d9cc0e/0000000000000000774ee007/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
        url("https://use.typekit.net/af/d9cc0e/0000000000000000774ee007/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
        url("https://use.typekit.net/af/d9cc0e/0000000000000000774ee007/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

body {
    font-family: $baseFont;
    font-size: $baseFontSize;
    line-height: $baseLineHeight;
    background-color: $appColorDark;
    color: $appColor;
}

// disable selection in the UI
* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

h1 {
    font-family: "gloridot", sans-serif;
    font-size: 4.5rem;
    text-transform: uppercase;
    color: $appColorFocus;
    letter-spacing: 0.05em;
    margin: $appMargin;
    padding-left: $appPadding;
}

h2 {
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin: $appMargin $appMargin $appMargin/2 $appMargin;
    line-height: 135%;
}

h3, h4 {
    padding: $appPadding;
    border-radius: $appBorderRadius;
    // font-variant: small-caps;
    text-transform: uppercase;
    font-size: .9rem;
    letter-spacing: 0.04em;
    color: $appColorSecondary;
    padding-bottom: $appPadding/2;
}

h3 {
    padding: $appPadding/2 $appPadding 0 $appPadding;
}

h4 {
    color: $appColorTeritiary;
    font-size: .8rem;
}

h5 {
    font-size: .9rem;
    color: $appColorTeritiary;
}

a {
    text-decoration: none;
}

p, li {
    line-height: 140%;
}

p {
    padding: $appPadding $appPadding;
}

li {
    padding: $appPadding/3 $appPadding;
}

.Slide a {
    color: $appColorTeritiary;
}

a:hover {
    color: $appColorFocus;
}

.No-Hover {
    color: $appColor;
}

.italics {
    font-style: italic;
}

.No-Bold {
    font-weight: 400;
}

::-webkit-scrollbar {
    scrollbar-width: thin;
    width: 14px;
  }
  
  ::-webkit-scrollbar-thumb {
    border: 1px solid $appBorderColor;
    background-color: #1c2022;
    // border-radius: $appBorderRadius;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #3a4047;
    // border-radius: $appBorderRadius;
    border: 1px solid $appBorderColor;
  }