.Footer {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    padding: $appPadding;
    background: #1c2022;
    border-top: 1px solid #3a4047;
    position: absolute;
    bottom: 0;
}

.Social-media a {
    margin: $appMargin;
    padding: $appPadding/2;
    color: $appColorFocus;
    border: 2px solid #1c2022;
}

.Social-media svg:hover path,
.MuiButtonBase-root svg:hover path {
    fill: white;
}
