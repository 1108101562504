
#Home {
    max-height: 100vh;
    overflow-y: hidden;
}

#Skip {
    position: absolute;
    bottom: $appPadding*2;
    right: $appPadding*2;
    visibility: hidden;
    z-index: 110;
}

#ContactMe {
    position: sticky;
    float: right;
    margin-bottom: $appPadding*2;
    bottom: $appPadding*2;
    right: $appPadding*2;
    z-index: 110;
}

.Intro div h2 {
    font-size: 2.5rem;
}

.Intro div {
    padding: 100px;
    border-radius: 100%;
    background: radial-gradient($appColorDark, transparent);
    z-index: 101;
    width: 50%;
    text-align: center;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    animation: 
      typing 2s steps(25, end) forwards;
}

.Intro div:nth-child(2) {
    animation-delay: 2s;
}

.Intro div:nth-child(3) {
    animation-delay: 4s;
}

.Intro div:nth-child(4) {
    animation-delay: 6s;
}

.Intro div:nth-child(5) {
    animation-delay: 8s;
}

.Intro div:nth-child(6) {
    animation-delay: 10s;
}

.Intro div:nth-child(7) {
    animation-delay: 12s;
}

.Intro div:nth-child(8) {
    animation-delay: 14s;
}

.Intro div:nth-child(9) {
    animation-delay: 21s;
}


@keyframes typing {
    // from { width: 0 }
    // to { width: 100%; opacity: 0; z-index: -100; }

    0% {
        opacity: 0;
    }

    1% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 480px) {

    .Intro div {
        width: 100%;
        padding: 100px 50px;

        h2 {
            font-size: 1.5rem;
        }
    }
}
